import { Stack, Typography } from '@mui/joy';
import { SYSTEM_COLOR } from 'lib/Enum';
import { SVGClock, SVGConfidential, SVGDemographic, SVGNonConfidential, SVGQuestionMark } from 'icons';

import { ToggleTooltip } from 'elements/tool-tip';
import { NotConfidentialTooltip } from './NotConfidentialTooltip';
import { ConfidentialTooltip } from './ConfidentialTooltip';

const ICON_SIZE = 46;
const TEXT_MARGIN = 1;

const InfoIcon = ({ text, icon, dataAttributes }) => (
  <div
    style={{ flex: 1 }}
    {...dataAttributes}
  >
    <Stack
      alignItems={'center'}
      spacing={{ xs: 1, md: 3 }}
    >
      {icon}
      <div>
        {text}
      </div>
    </Stack>
  </div>
);

export const InfoIcons = (props) => {
  const {
    t,
    survey_time_text,
    has_demographics,
    below_min_responses_for_report
  } = props;

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      sx={{ textAlign: 'center' }}
      spacing={8}
    >
      {below_min_responses_for_report ? (
        <InfoIcon
          icon={(
            <SVGNonConfidential
              size={ICON_SIZE}
              color={SYSTEM_COLOR.PRIMARY}
              ariaHidden={'true'}
            />
          )}
          dataAttributes={{ 'data-cy': 'not-confidential-msg' }}
          text={(
            <>
              <Typography sx={{ marginBottom: TEXT_MARGIN }}>
                <strong>
                  {t('Not anonymous')}
                </strong>
              </Typography>

              <Typography level={'body-sm'}>
                {t('Your answers can be linked back to you.')}
                <ToggleTooltip
                  sx={{ maxWidth: 300, p: 2 }}
                  buttonProps={{ sx: { marginLeft: .5, verticalAlign: 'text-top' } }}
                  tooltipContent={<NotConfidentialTooltip />}
                  ariaLabel={t('Select for information on why answers are linked to you.')}
                >
                  <SVGQuestionMark
                    size={16}
                  />
                </ToggleTooltip>
              </Typography>
            </>
          )}
        />
      ) : (
        <InfoIcon
          icon={(
            <SVGConfidential
              size={ICON_SIZE}
              color={SYSTEM_COLOR.PRIMARY}
              ariaHidden={'true'}
            />
          )}
          dataAttributes={{ 'data-cy': 'confidential-msg' }}
          text={(
            <>
              <Typography sx={{ marginBottom: TEXT_MARGIN }}>
                <strong>
                  {t('Confidential')}
                </strong>
              </Typography>
              <Typography
                level={'body-sm'}
              >
                {t("Your answers can't be linked back to you.")}
                <ToggleTooltip
                  sx={{ maxWidth: 300, p: 2 }}
                  buttonProps={{ sx: { marginLeft: .5, verticalAlign: 'text-top' } }}
                  tooltipContent={<ConfidentialTooltip />}
                  ariaLabel={t('Select for information on how we protect your identity.')}
                >
                  <SVGQuestionMark
                    size={16}
                  />
                </ToggleTooltip>
              </Typography>
            </>
          )}
        />
      )}

      {(has_demographics && !below_min_responses_for_report) && (
        <InfoIcon
          icon={(
            <SVGDemographic
              size={ICON_SIZE}
              color={SYSTEM_COLOR.PRIMARY}
              ariaHidden={'true'}
            />
          )}
          dataAttributes={{ 'data-cy': 'welcome-page-your-demographics' }}
          text={(
            <>
              <Typography sx={{ marginBottom: TEXT_MARGIN }}>
                <strong>{t('Identity protected')}</strong>
              </Typography>
              <Typography level={'body-sm'}>
                {t('Survey results are analysed in groups.')}
              </Typography>
            </>
          )}
        />
      )}

      <InfoIcon
        icon={(
          <SVGClock
            size={ICON_SIZE}
            color={SYSTEM_COLOR.PRIMARY}
            ariaHidden={'true'}
          />
        )}
        dataAttributes={{ 'data-cy': 'welcome-page-time-estimate' }}
        text={(
          <>
            <Typography sx={{ marginBottom: TEXT_MARGIN }}>
              <strong>{survey_time_text}</strong>
            </Typography>
            <Typography level={'body-sm'}>
              {t('Estimated survey completion time.')}
            </Typography>
          </>
        )}
      />
    </Stack>
  );
};
