import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { Stack, Typography } from '@mui/joy';

export const ConfidentialTooltip = () => {
  const { t } = useTranslation('welcome_page');

  const { min_responses_for_report } = useOutletContext();
  const reportingThreshold = min_responses_for_report  - 1;

  return (
    <Stack
      spacing={2}
    >
      <Typography level={'body-md'}>
        <strong>
          {t('No one can see your answers on their own.')}
        </strong>
      </Typography>
      <Typography level={'body-md'}>
        {t(
          'They must be grouped with at least {{reportingThreshold}} more people to create a report.',
          { reportingThreshold: reportingThreshold }
        )}
      </Typography>
      <Typography level={'body-md'}>
        {t('AskYourTeam does this to ensure everyone is treated equally and so you feel comfortable being honest.')}
      </Typography>
    </Stack>
  );
};
