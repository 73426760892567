import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/joy';

export const NotConfidentialTooltip = () => {
  const { t } = useTranslation('welcome_page');
  return (
    <Stack
      spacing={2}
    >
      <Typography level={'body-md'}>
        <strong>
          {t('Why are my answers linked to me?')}
        </strong>
      </Typography>
      <Typography level={'body-md'}>
        {t('This is usually so the person reviewing the survey results can follow-up with you if they need to.')}
      </Typography>
    </Stack>
  );
};
