import { Button, Skeleton, Stack, Typography } from '@mui/joy';
import { PageHeaderLoadingSkeleton } from 'elements/PageHeaderLoadingSkeleton';

export const IntroductionLoadingSkeleton = () => {
  return (
    <>
      <PageHeaderLoadingSkeleton />
      <section style={{ paddingTop: 40 }}>
        <Stack
          alignItems={'center'}
          spacing={{ xs: 5, md: 8 }}
        >
          <section style={{ maxWidth: '720px' }}>
            <Typography level={'h1'} sx={{ textAlign: 'center', mb: 3 }}>
              <Skeleton>
                Welcome
              </Skeleton>
            </Typography>

            <Typography level={'body-md'} sx={{ textAlign: 'center' }}>
              <Skeleton>
                lorem ipusm dolor sit amet, consectetur adipiscing elit. Nulla
                consectetur sed quam et aliquam. Nam eget nisl sit amet mauris
                ullamcorper dapibus. Suspendisse potenti. Sed sed lorem eget
                sapien blandit gravida. Sed euismod, nisl et aliquam.
              </Skeleton>
            </Typography>
          </section>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ textAlign: 'center' }}
            spacing={8}
          >
            <article
              style={{ flex: 1 }}
            >
              <Stack
                alignItems={'center'}
                spacing={{ xs: 1, md: 3 }}
              >
                <Skeleton variant={'circular'} width={48} height={48} />
                <div>
                  <Typography sx={{ mb: 1 }}>
                    <Skeleton>Confidential</Skeleton>
                  </Typography>

                  <Typography
                    level={'body-sm'}
                  >
                    <Skeleton>Your answers can't be linked back to you.</Skeleton>
                  </Typography>
                </div>
              </Stack>
            </article>

            <article
              style={{ flex: 1 }}
            >
              <Stack
                alignItems={'center'}
                spacing={{ xs: 1, md: 3 }}
              >
                <Skeleton variant={'circular'} width={48} height={48} />
                <div>
                  <Typography sx={{ mb: 1 }}>
                    <Skeleton>Confidential</Skeleton>
                  </Typography>

                  <Typography
                    level={'body-sm'}
                  >
                    <Skeleton>Your answers can't be linked back to you.</Skeleton>
                  </Typography>
                </div>
              </Stack>
            </article>

            <article
              style={{ flex: 1 }}
            >
              <Stack
                alignItems={'center'}
                spacing={{ xs: 1, md: 3 }}
              >
                <Skeleton variant={'circular'} width={48} height={48} />
                <div>
                  <Typography sx={{ mb: 1 }}>
                    <Skeleton>Confidential</Skeleton>
                  </Typography>

                  <Typography
                    level={'body-sm'}
                  >
                    <Skeleton>Your answers can't be linked back to you.</Skeleton>
                  </Typography>
                </div>
              </Stack>
            </article>
          </Stack>
          <Button
            className={'large-button'}
            size={'lg'}
          >
            Start survey
            <Skeleton />
          </Button>
        </Stack>
      </section>
    </>
  );
};
