import { useCallback, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Typography } from '@mui/joy';

import { getAPI } from 'lib/APIHelpers';
import { useLocale, useNavigateKeepParams } from 'hooks';
import { PageHeader } from 'elements/PageHeader';
import { LogoCentered } from 'elements/logo/LogoCentered';
import { MainWrapper } from 'elements/MainWrapper';
import { WYSIWYGRenderer } from 'elements/WYSIWYGRenderer/WYSIWYGRenderer';
import { SVGAlertSolid } from 'icons';
import { RESPONDENT_SURVEY_STATUS_VALUES } from 'lib/Enum';

import { IntroductionLoadingSkeleton } from './IntroductionLoadingSkeleton';
import { InfoIcons } from './InfoIcons';

export const Introduction = () => {
  const [locale] = useLocale();
  const [loading, setLoading] = useState(false);
  const { feedbackID } = useParams();
  const navigateKeepParams = useNavigateKeepParams();

  const { t } = useTranslation('welcome_page');

  const { isPending, data, isFetching } = useQuery({
    queryKey: ['getWelcomeData', feedbackID],
    queryFn: () => getAPI(`api/v2/feedback/${feedbackID}/introduction`),
    throwOnError: true,
  });

  const {
    survey_time_with_locales,
    min_responses_for_report,
    has_demographics,
    is_kiosk_mode,
    respondent: {
      single_reporter,
      self_reporter,
    } = {},
    text_templates: {
      introduction: {
        body
      } = {},
      introduction_self_assessment: {
        body: body_self_assessment
      } = {},
      kiosk_introduction: {
        body: kiosk_body
      } = {},
      kiosk_introduction_self_assessment: {
        body: kiosk_self_assessment_body
      } = {}
    } = {},
  } = data || {};

  const {
    account_organisation_name,
    image,
    oldSurveyTakingInProgress,
    redirect_url,
    setRespondentStatus,
    onStartSurvey,
    respondent_survey_status
  } = useOutletContext();

  const navigateToDemographics = useCallback(
    () => {
      // For kiosk mode time out modal to appear on demographics page
      setRespondentStatus(RESPONDENT_SURVEY_STATUS_VALUES.ENTERING_DEMOGRAPHICS);
      navigateKeepParams(`/${feedbackID}/demographics`);
    },
    [navigateKeepParams, feedbackID, setRespondentStatus]
  );

  const onClick = useCallback(
    () => {
      setLoading(true);
      if (oldSurveyTakingInProgress) {
        window.location.href = redirect_url;
      }

      if (has_demographics) {
        navigateToDemographics();
      } else {
        onStartSurvey({});
      }
    },
    [
      has_demographics,
      oldSurveyTakingInProgress,
      redirect_url,
      onStartSurvey,
      navigateToDemographics
    ]
  );

  const welcomePageTemplate =
    is_kiosk_mode
      ? self_reporter ? kiosk_self_assessment_body : kiosk_body
      : self_reporter ? body_self_assessment : body;

  if (isFetching || isPending || welcomePageTemplate === undefined) return <IntroductionLoadingSkeleton />;

  const template = welcomePageTemplate[locale] || welcomePageTemplate?.en;
  const survey_time_text = survey_time_with_locales[locale] || survey_time_with_locales?.en;
  const below_min_responses_for_report = min_responses_for_report <= 1 || self_reporter || single_reporter;

  return (
    <>
      <PageHeader
        hideLogo
        hideBackToWelcomePage
        hideSave
      />
      <MainWrapper style={{ paddingTop: 40 }}>
        <LogoCentered
          image={image}
          account_organisation_name={account_organisation_name}
        />
        <Stack
          alignItems={'center'}
          spacing={{ xs: 5, md: 8 }}
          component={'section'}
        >
          <WYSIWYGRenderer template={template} />
          <InfoIcons
            t={t}
            survey_time_text={survey_time_text}
            below_min_responses_for_report={below_min_responses_for_report}
            has_demographics={has_demographics}
          />
          {below_min_responses_for_report && (
            <Typography startDecorator={<SVGAlertSolid size={24} />}>
              {t('This survey is set up to gather individual feedback. Your answers can be identified.')}
            </Typography>
          )}
          <Button
            size={'lg'}
            onClick={onClick}
            data-cy={'welcome-page-start-button'}
            loadingPosition={'end'}
            loading={loading}
          >
            {respondent_survey_status == RESPONDENT_SURVEY_STATUS_VALUES.IN_PROGRESS ? t('Continue') : t('Start survey')}
          </Button>
        </Stack>
      </MainWrapper>
    </>
  );
};
