import { lazy } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Introduction } from './introduction/Introduction';

const LocalePage = lazy(() => import('./locale/LocalePage'));

export const IntroWrapper = () => {
  const {
    survey_locales,
    locales,
    show_locale_page,
    showWelcomePage,
    navigateToWelcome,
  } = useOutletContext();

  const hasLocales = (
    survey_locales && survey_locales.length > 1)
    || (locales && locales.length > 1);

  if (hasLocales && !showWelcomePage && show_locale_page) return (
    <LocalePage navigateToWelcome={navigateToWelcome} />
  );
  return <Introduction />;
};

export default IntroWrapper;
